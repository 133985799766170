<!-- 社会责任 -->
<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/ja-合作与发展banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 中间内容区域 -->
    <div class="content">
      <h2 class="H2">企業の社会的責任</h2>
      <img style="width: 100%;margin-top: 3rem;" src="@/assets/cooperation/social_responsibility/ja-企业社会责任.png" alt="" />
      <div class="content_1">
     
          <img src="@/assets/cooperation/social_responsibility/ja-社会责任.png" alt="">
       
        <div class="text">
          <div v-for="item in textList" :key="item.id">
            <li>{{ item.name }}</li>
            <p style="text-indent: 2em;">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/ja-合作与发展banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
    <!-- 内容区域 -->
    <div class="content">
      <h2>企業の社会的責任</h2>
      <img  src="@/assets/cooperation/social_responsibility/ja-企业社会责任.png" alt="" />
      <h2>盛泰-社会的責任</h2>
      <div v-for="item in textList" :key="item.id">
        <li>{{item.name}}</li>
        <p>{{item.title}}</p>
      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRoute } from "vue-router";
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { foot, headers, move_header, move_foot },

  setup() {
    const route = useRoute();
    const state = reactive({
      textList: [
        { id: 1, name: '研究開発技術の自主革新を高める', title: '盛泰光電は創立以来、企業の社会貢献は一身の使命を深く理解し、収入の持続的な成長と同時に、盛泰も企業の社会責任を全力で履行している。また、映像システムの新技術の発展動向を常に注視し、技術発展の趨勢を深く把握し、研究開発への投資を増やし、自主革新を強化し、技術突破を加速する。' },
        { id: 2, name: '環境改善の成績', title: '会社は環境保護と自然調和の維持を生産の重要な一環として、環境保護に関する法律、法規及び国際公約を遵守しています。汚染を防止し、資源エネルギーの消耗を低下させ、環境性能を持続的に改善しています。そして、社員の環境意識を育成し、環境保全活動を支援・参加することを提唱します。また、お客様とその端末市場の環境要求に合った製品を販売します。' }
      ]
    });
    onMounted(() => {
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.box {

  width: 100%;

  .content {
    max-width: 1920px;
    margin: 0 auto;
    width: 70%;

    li {
      list-style-type: square;
      font-size: 1.7rem;
      font-weight: bold;
    }
  
    .content_1 {
      position: relative;
      box-sizing: border-box;
      margin: 50px 0;
      img{
        width: 100%;
      }
      .text{
        position: absolute;
        left:300px;top:300px;
        width: 65%;
        div{
          p{
            font-size: 1.2rem;
          }
        }
      }
      .text {
        flex: 3;
        padding: 50px;

        p {
          font-size: 1.2rem;
        }
      }
    }

    .H2 {
      margin-top: 50px;
      font-size: 1.7rem;
    }

    .H2::before {
      content: "";
      width: 30%;
      height: 2px;
      background-color: #c7000b;
      position: absolute;
      margin-top: 40px;
      left: 20.5%;
      transform: translateX(-50%);
      // top:55px;
      min-width: 35px;
      max-width: 35px;
    }
  }
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;

    .content {
      padding: 30px;
      img{width: 100%;}
       li {
      list-style-type: square;
      font-size: 70px;
      font-weight: bold;
    }
    p{
      font-size: 50px;
    }
      h2 {
        text-align: center;
        font-size: 80px;
      }

      h2::before {
        content: "";
        width: 30%;
        height: 8px;
        background-color: #c7000b;
        position: absolute;
        margin-top: 100px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        max-width: 100px;
      }

    }
  }
}
</style>